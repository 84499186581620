<template>
  <div class="last-posts-three" :class="`${ cfgCategory }`">

    <b-container class="mt-5 mb-5 pt-2">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fab fa-readme"></i> {{ cfgTitle }}</h1>
          <hr class="line sm">
        </b-col>
      </b-row>
      <b-row>
        <b-col 
          cols="12" lg="4" md="6" sm="6"
          class="mb-4"
          v-for="postCategory in lastPostsByCategory"
          :key="postCategory.id"
          >
          <div class="image-cover box-5 mb-3 z-depth-2">
            <b-img
              fluid-grow
              :src="`${ postCategory.image_home_local ? path.storage_files + postCategory.image_home_local.formats.small.url : postCategory.image_home }`"
              class="zoom v-100"
            ></b-img >
          </div>
          <div class="block mt-1 mb-1">
            <b-badge 
              class="red darken-1" 
              v-b-tooltip.hover 
              title="Video Blog" 
              v-if="postCategory.type_publication_id.id === 5 "
            >
              <i class="fab fa-youtube"></i>
            </b-badge>&nbsp;
            <b-badge class="rojo ">
              <b-link :to="`/${postCategory.category_id.slug}/`" class="white-text">
                <i class="fas fa-tag"></i>&nbsp;
                {{ postCategory.category_id.name }}
              </b-link>
            </b-badge>&nbsp;
            <b-badge class="verde">
              <i class="fas fa-calendar-alt"></i>&nbsp;
              {{ postCategory.published_at | moment('DD/MM/YYYY') }} 
            </b-badge>
          </div>
          <h5 class="latest-post medium">
            <b-link :to="`/${postCategory.category_id.slug}/articulo/${postCategory.canonical}`"><!-- Ajustar para Slug -->
              {{ postCategory.title }}
            </b-link>
          </h5>
        </b-col>
      </b-row>

      <b-row v-if="cfgShowLinkMore === 'false'" />
      <b-row v-else>
        <b-col cols="12" class=""> 
          <b-button
            size="lg"
            style="float:right"
            class="rojo white-text"
            :to="`${$route.path}/articulos`">
            <i class="fas fa-external-link-square-alt"></i>
            Ver {{ cfgTitle }}
          </b-button>
        </b-col>
      </b-row>

    </b-container>

  </div>
</template>

<script>
export default {
  name: 'LastPostsThreeByCategory',
  components: {},

  props: {
    cfgTitle: String,
    cfgCategory: String,
    cfgLimitPost: String,
    cfgShowLinkMore: String,
  },

  data() {
    return {
      lastPostsByCategory:[],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted(){},


  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint + '/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=published_at:desc&_limit='+ this.cfgLimitPost +'&post_type_in=3&post_type_in=4&post_type_in=5&category_id.slug='+ this.cfgCategory // <- Ajuste segun Categoria
    ).then(response => response.json())
    .then(data => {
      this.lastPostsByCategory = data;
      console.log(data);
    })
    .catch(err => {
      console.error(err);
    });
  },
  /* End Ajuste */


  methods: {},
 
}
</script>
