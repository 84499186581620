<template>
  <div
    v-if="bannerByCategoryCount != 0"
    class="single-main-banner"
    :class="`${cfgCategory}`"
  >
    <section
      class="mt-5 mb-5 pt-5 pb-5 blue-grey darken-3 z-depth-4"
      :class="`${cfgCategory}`"
    >
      <b-container class="">
        <b-row>
          <b-col cols="12" class="content-banner">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="7500"
              controls
              indicators
              img-width="1440"
              img-height="580"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide
                v-for="banner in bannerByCategory"
                :key="banner.id"
              >
                <template #img>
                  <a :href="banner.link">
                    <img
                      class="d-block img-fluid w-100"
                      width="1440"
                      height="480"
                      :src="`${path.storage_files + banner.images.url}`"
                      alt="image slot"
                    />
                  </a>
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "SingleBanner",
  components: {},

  props: {
    cfgTitle: String,
    cfgCategory: String,
  },

  data() {
    return {
      bannerByCategory: [],
      bannerByCategoryCount: 0,
      slide: 0,
      sliding: null,
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    };
  },

  mounted() {},

  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint +
        "/banners/?isGlobal=false&category.slug=" +
        this.cfgCategory // <- Ajuste segun Categoria
    )
      .then((response) => response.json())
      .then((data) => {
        this.bannerByCategory = data;
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });

    await fetch(
      this.path.endpoint +
        "/banners/count/?isGlobal=false&category.slug=" +
        this.cfgCategory // <- Ajuste segun Categoria
    )
      .then((response) => response.json())
      .then((data) => {
        this.bannerByCategoryCount = data;
      });
  },
  /* End Ajuste */

  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
