<template>
  <div class="programs-category program-three-columns" :class="`${ cfgCategory + ' ' + cfgBgColor }`">
    <b-container class="mt-5">
      <b-row class="mb-4"  align-v="center">
        <b-col cols="12">
          <h1 class="display-4  super-strong"> {{ cfgTitle }}</h1>
          <hr class="line" />
        </b-col>
      </b-row>

      <b-row>
        <b-col 
          cols="12" lg="4" md="6" sm="6"
          v-for="program in programs" 
          :key="program.id" 
          class="mb-4 "
        >
          <section class="last-posts-four">
            <div class="image-cover box-10 mb-3 z-depth-2" vertical-align="center">
              <b-img
                :src="`${ program.image_home_local ? path.storage_files + program.image_home_local.url : program.image_home }`" 
                fluid-grow 
                :alt="`${program.title}`" 
                class="zoom"
              ></b-img>
            </div>
            <div class="block mb-2">
              <b-badge class="rojo">
                <b-link :to="`/${program.category.slug}/`" class="white-text">
                  <i class="fas fa-tag"></i>&nbsp;
                  {{ program.category.name }}
                </b-link>
              </b-badge>&nbsp;
              <b-badge class="verde">
                <i class="fas fa-calendar-alt"></i>&nbsp;
                {{ program.published_at | moment('DD/MMM/YYYY') }} 
              </b-badge>
            </div>
            <h4 class="mb-4">
              <b-link :to="`/${program.category.slug}/programa/${program.canonical}`" :class="`${cfgLinkColor}`">
                {{ program.title }}
              </b-link>
            </h4>
          </section>
        </b-col>
      </b-row>


    </b-container>
  </div>
</template>

<script>

export default {
  name: 'ProgramsFourColumns',

  props: {
    cfgTitle: String,
    cfgCategory: String,
    cfgBgColor: String,
    cfgLinkColor: String,
  },



  components: {},



  data() {
    return {
      programs:[],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },

    }
  },



  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint + '/programs/?category.slug='+ this.cfgCategory +'&_publicationState=preview&published_at_null=false' // <- Ajuste segun Categoria
    ).then(response => response.json())
    .then(data => {
      this.programs = data;
      console.log(data);
    })
    .catch(err => {
      console.error(err);
    });
  },
  /* End Ajuste */
  

  methods: {}
 
}
</script>
