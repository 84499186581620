<template>
<div :class="`pb-4 pt-2  ${ videoBgColor ? videoBgColor : 'transparent' }`"> 
  <b-container class="mt-5 pt-4" :class="`youtube-${videoId}`">
    <b-row>
      <b-col cols="12">
        <section 
          :class="`video-post mb-5 p-5 box-20  ${ videoMaterialColor ? videoMaterialColor : 'transparent' }`"
        >
          <h1><i class="fab fa-youtube"></i> {{ videoTitle }} </h1>
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="`https://www.youtube-nocookie.com/embed/${ videoId }`"
            allowfullscreen
            class="video box-10 z-depth-2"
          ></b-embed>
          <p class="lead text-center mt-2 no-text-shadow"> {{ videoDescription }}</p>
        </section>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>

export default {
  name: 'Video',
  components: {
  },

  props: {
    videoTitle: String,
    videoId: String,
    videoDescription: String,
    videoMaterialColor: String,
    videoBgColor: String,
  },
  

  data() {
    return {}
  },

  mounted() {
  },

  methods: {
  },


}
</script>
