<template>
  <section class="category-header-slide">
    <div class="header-category-slide z-depth-5">
      <section class=" mb-5 z-depth-4" :class="`${slugCategory}`">
        <section
          v-for="slideCat in slideCategory"
          :key="slideCat.id"
          class="content-banner"
        >
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="7500"
            controls
            indicators
            img-width="1440"
            img-height="580"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              v-for="carrousel in slideCat.carrousel"
              :key="carrousel.id"
              :img-src="`${path.storage_files + carrousel.image.url}`"
            >
              <div class="carousel-content">
                <b-container class="">
                  <b-row class="">
                    <b-col cols="12">
                      <h3
                        class="display-6 white-text text-center text-shadow super-strong"
                      >
                        {{
                          carrousel.alternativeText
                            ? carrousel.alternativeText
                            : ""
                        }}
                        TEST
                      </h3>
                      <p class="lead text-shadow d-none d-sm-none d-md-block">
                        {{ carrousel.caption ? carrousel.caption : "" }}
                      </p>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </section>
      </section>
    </div>

    <div>
      <h1
        class="display-3 blue-grey-text text-darken-4 text-center text-shadow super-strong"
      >
        {{ nameCategory }}
      </h1>
    </div>
  </section>
</template>
<script>
export default {
  name: "HeaderCategorySlide",
  components: {},

  props: {
    slugCategory: String,
    nameCategory: String,
    imageCategory: String,
  },

  data() {
    return {
      slideCategory: {},
      slide: 0,
      sliding: null,
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    };
  },

  /*
   * Configuracion Inicial
   */
  async created() {
    await fetch(this.path.endpoint + "/categories/?slug=" + this.slugCategory)
      .then((response) => response.json())
      .then((data) => {
        this.slideCategory = data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */

  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
