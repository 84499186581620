<template>
  <div class="outstanding-primary-two-secondaries-category">
    <b-container class="mt-5 mb-5">
      <b-row class="">
        <b-col cols="12" class="">
          <h1><i class="fas fa-medal"></i> {{ cfgTitle }} </h1>
          <hr class="line">
        </b-col>
      </b-row>
      <div class="mb-5">
        <b-row class="">
          <b-col class="post-primary" cols="12" lg="8" md="8" sm="6">
            <b-col
              class="z-depth-2 blue-grey lighten-5 m-2 back"
              v-for="ppost in postsPrimary"
              :key="ppost.id"
              :style="`background-image: url(${ ppost.image_home_local ? path.storage_files + ppost.image_home_local.formats.large.url : ppost.image_home })`"
            >
              <div class="gradiente-homepage-post-primary text-white text-center p-4">
                <h3 class="medium text-shadow">
                  <b-link :to="`/${ppost.category_id.slug}/articulo/${ppost.canonical}`"><!-- Ajustar para Slug -->
                    {{ ppost.title }}
                  </b-link>
                </h3>
                <p class="light text-shadow d-none d-sm-none d-md-none d-lg-block">{{ ppost.summary }}</p>
                <div class="block">
                  <b-badge class="rojo">
                    <b-link :to="`/${ppost.category_id.slug}/`">
                      <i class="fas fa-tag"></i>&nbsp;
                      {{ ppost.category_id.name }}
                    </b-link>
                  </b-badge>&nbsp;
                  <b-badge class="dorado-obscuro">
                    <i class="fas fa-calendar-alt"></i>&nbsp;
                    {{ ppost.published_at | moment('DD/MMM/YYYY') }} 
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-col>
          <b-col class="" cols="12" lg="4" md="4" sm="6">
            <b-col
              cols="12"
              class="post-secondary mt-2 mb-3 z-depth-2 blue-grey lighten-5 clearfix"
              v-for="spost in postsSecondary"
              :key="spost.id" 
              :style="`background-image: url(${ spost.image_home_local ? path.storage_files + spost.image_home_local.formats.medium.url : spost.image_home })`"
            >
              <div class="gradiente-homepage-post-secondary text-white text-center p-3">
                <h5 class="medium text-shadow">
                <b-link :to="`/${spost.category_id.slug}/articulo/${spost.canonical}`"><!-- Ajustar para Slug -->
                  {{ spost.title }}
                </b-link>
                </h5>
                <div class="block">
                  <b-badge class="rojo">
                    <b-link :to="`/${spost.category_id.slug}/`">
                      <i class="fas fa-tag"></i>&nbsp;
                      {{ spost.category_id.name }}
                    </b-link>
                  </b-badge>&nbsp;
                  <b-badge class="verde">
                    <i class="fas fa-calendar-alt"></i>&nbsp;
                    {{ spost.published_at | moment('DD/MMM/YYYY') }} 
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-col>

        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import DataBlogs from "@/services/data-blogs";

export default {
  name: 'PrimaryTwoSecondariesCategory',
  components: {
    DataBlogs,
  },

  props: {
    cfgTitle: String,
    cfgCategory: String,
  },

  data() {
    return {
      postsPrimary: [],
      postsSecondary:[],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {
  },



  /*
   * Ajuste segun categoria
   */
  async created() {

    await fetch(
      this.path.endpoint + '/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=published_at:desc&_limit=1&post_type=5&category_id.slug='+ this.cfgCategory // <- Ajuste segun Categoria
    ).then(response => response.json())
    .then(data => {
      this.postsPrimary = data;
      //console.log(data);
    })
    .catch(err => {
      console.error(err);
    });

    await fetch(
      this.path.endpoint + '/blogs/?type_publication_id_in=1&type_publication_id_in=5&_sort=published_at:desc&_limit=2&post_type=4&category_id.slug='+ this.cfgCategory // <- Ajuste segun Categoria
    ).then(response => response.json())
    .then(data => {
      this.postsSecondary = data;
      //console.log(data);
    })
    .catch(err => {
      console.error(err);
    });

  },
  /* End Ajuste */



  methods: {},

}
</script>
